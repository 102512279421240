import React from "react";
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from "../components/layout";
import LocationSelector from "../components/locationSelector";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import Seo from 'gatsby-plugin-wpgraphql-seo';

// import styles from "./404.module.scss";
import * as styleSheet from "./404.module.scss"

const NotFoundPage = () => {
	const data = useStaticQuery(graphql`{
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  appointmentPage: allWpPage(
    filter: {template: {templateName: {eq: "Appointments Page"}}}
  ) {
    nodes {
      link
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  appointmentPage: allWpPage(
    filter: {template: {templateName: {eq: "Appointments Page"}}}
  ) {
    nodes {
      link
    }
  }
  locations: allWpLocation(sort: {fields: title}) {
    nodes {
      id
      link
      title
			birdeyeLocation {
				businessId
				timezone
				location {
					address1
					address2
					city
					state
					zip
					lat
					lng
				}
				name
				phone
				hoursOfOperations {
					day
					isOpen
					workingHours {
						startHour
						endHour
					}
				}
			}
      ACFLocation {
        menuTitle
        bookMyAppointmentLink {
          target
          title
          url
        }
      }
    }
  }
}
`);

	const {
		options: {
			headerSection: {
				ACFHeaderOptions: {
					mainNavigationSettings: {
						headerLogo
					} 
				}
			}

		},
		locations: {
			nodes: locations
		},
		rightArrowYellow,
		introBGMobile,
		introBGTablet,
		introBGDesktop,
		introBGXL,
		arrowBlue,
		appointmentPage
	} = data;

	return (
		<Layout>
			<Seo title="404: Not found" />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					</div>
					<div className={styleSheet.leftbottom}>
						<span className={styleSheet.serviceheading}>View our locations:</span>
						{ locations && locations.length > 0 && <LocationSelector locations={locations} /> }
						<div className={styleSheet.locationlist}>
							<div className={styleSheet.inner}>
								<ul className={styleSheet.list}>
									{ locations && locations.map((location, index) => {
										return (
											<li key={`lstloc-'${location.id}`} className={styleSheet.listitem}>
												<Link to={location.link} className={styleSheet.link}>
													<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
													<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: location.title }}></span>
												</Link>	
											</li>
										)
									})}
								</ul>
							</div>
						</div>
						{ appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styleSheet.request}>Request appointment</Link> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.servicename} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">Page not found</h1>
					<div className={styleSheet.heading} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"><p>Oops! That page can’t be found.</p></div>
					<div className={styleSheet.blurb} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in">
						<p>It looks like nothing was found at this location. Check for a url error then refresh this page, or return to the homepage.</p>
					</div>
					<Link to="/" className={styleSheet.link} data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease-in">Return to homepage <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></Link>
				</div>
			</div>
		</Layout>
	)
}

export default NotFoundPage;
